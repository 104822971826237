// TODO: if we end up changing colors use template active class
.pagination-active {
  &,
  &:focus,
  &:hover {
    background-color: #cb0c9f;
    border: none;
    color: #fff !important;
    pointer-events: none;
  }
}
