.image-container {
  height: 125px;
  width: 125px;
}

.more-options-modal {
  background-color: white;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  right: -53px;
  top: 60px;

  &::before {
    background: white;
    box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.1);
    content: '';
    height: 14px;
    left: 25%;
    position: absolute;
    top: -7px;
    transform: translateX(-50%) rotate(45deg);
    -webkit-transform: translateX(-50%) rotate(45deg);
    width: 14px;
    z-index: -1;
  }
}

.gray-filter {
  filter: invert(58%) sepia(2%) saturate(1585%) hue-rotate(174deg) brightness(88%) contrast(95%);
}
