.gradient-scroll {
  background: linear-gradient(180deg, #ffffff 13.13%, rgba(255, 255, 255, 0) 61.78%);
  height: 130px;
}

.scroll-container {
  width: calc(100% + 3rem);
}

@media (min-width: 1200px) {
  .scroll-container {
    width: calc(100% + 4rem);
  }
}
