.selected {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle,
    rgba(249, 159, 215, 1) 0%,
    rgba(237, 60, 168, 0.7) 35%,
    rgba(231, 59, 166, 0) 60%
  );
}
