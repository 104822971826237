.spinner {
  animation: spin 1s ease-in-out infinite;
  height: 110px;
  mask: url('/assets/img/spinner-mask.svg');
  -webkit-mask: url('/assets/img/spinner-mask.svg');
  width: 110px;
}

.spinner-background {
  animation: grow 1s ease-in-out infinite;
  background: conic-gradient(
    rgba(238, 15, 187, 1),
    rgba(238, 15, 187, 1),
    rgba(36, 151, 255, 1),
    rgba(36, 151, 255, 0) 300deg
  );
  border-radius: 50%;
  border-radius: 50%;
  height: 100px !important;
  width: 100px !important;
}

.rounded-cap {
  animation: grow 1s ease-in-out infinite;
  background-color: rgba(238, 15, 187, 1);
  border-radius: 50%;
  height: 10px;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translate(-50%) !important;
  width: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes grow {
  0% {
    height: 10px;
    top: 0;
    transform: scale(1);
    width: 10px;
  }
  70% {
    height: 15px;
    top: -5px;
    transform: scale(1.1);
    width: 15px;
  }
  100% {
    height: 10px;
    top: 0;
    transform: scale(1);
    width: 10px;
  }
}
