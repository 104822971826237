.triangle-up {
  top: 0;
  left: 26px;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid white;
  width: 0;
}

.options-container {
  max-height: 140px;
}
