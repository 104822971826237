.collection-card {
  display: inline-block;
  max-width: 428px;
  width: 100%;
}

.divider {
  background: linear-gradient(#e0e1e200, #e0e1e2ff, #e0e1e200);
  height: 1px;
  width: 100%;
}
