.input-group-prepend {
  border-bottom-left-radius: 0.5rem;
  border-color: #adb5bd;
  border-style: solid;
  border-top-left-radius: 0.5rem;
  border-width: 1px 0px 1px 1px;
}

.input-group {
  &:focus-within {
    box-shadow: 0 0 0 2px #e293d3;
  }

  .form-control:focus {
    border-left: 0px !important;
  }

  input {
    &:focus {
      border-left: 1px solid transparent !important;
      outline: none;
    }
  }

  .form-control:focus {
    border-left: 0px !important;
  }
}
