.checkbox {
  height: 26px;
  width: 26px;

  &::after {
    background: url('/assets/img/icons/check.svg') no-repeat;
    background-position: center;
    background-size: 65%;
    content: '' !important;
    display: block;
    height: 100%;
    width: 100%;
  }
}
