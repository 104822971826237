.sculpt-filter-dropdown {
  background-color: white;
  box-shadow: 0px 3px 6px #00000040;
  width: 400px;
  z-index: 1;

  .option-filter-container {
    max-height: 300px;
  }

  &::before {
    background: white;
    box-shadow: -0px 0px 3px rgba(0, 0, 0, 0.25);
    content: '';
    height: 14px;
    left: 15%;
    position: absolute;
    top: -7px;
    transform: translateX(-50%) rotate(45deg);
    width: 14px;
    z-index: -1;
    -webkit-transform: translateX(-50%) rotate(45deg);
  }
}
