.texture-input {
  background: linear-gradient(180deg, #ffffff 0%, rgba(124, 124, 124, 0.4) 100%),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-position: center, right 0.75rem center;
  background-repeat: no-repeat;
  background-size: cover, 16px 12px;
}

.info-button {
  font-size: 0.75rem;
  height: 1rem;
  width: 1rem;
  z-index: 1;
}
