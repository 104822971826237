.form-control {
  max-width: 176px;

  &:hover {
    transform: scale(1.02);
  }
}

.group-logo-background {
  background-color: white;
  border-radius: 8px;
  height: 143px;
  padding: 0.25rem;
  position: relative;
  width: 143px;
}

.file-list > div {
  max-width: 140px;
}
